var Cameraland = Cameraland || {};

Cameraland.ThemeInit = class {
    constructor(options) {
        this.initUnveil = this.initUnveil.bind(this);
        this.initAll = this.initAll.bind(this);

        this.options = $j.extend({}, {
            unveilImg: 'img[data-src]'
        }, options);

        this.initAll();
    }

    initUnveil() {
        $j(this.options.unveilImg).unveil();
    }

    initAll() {
        $j(document).ready(() => {
            this.initUnveil();
        });
    }

};

$j(function () {
    new Cameraland.ThemeInit();
});